<template>
    <div>
        <form @submit.prevent="create">
            <div class="modal-body modal-row2 modal-height-max">
                <div class="row gutters mt-2">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                         v-if="allotments">
                        <div class="form-group">
                            <label
                                    for="allotment"
                                    class="required">{{ t('LOTS.ALLOTMENT') }}: </label>
                            <Select2
                                    :settings="{ width: '100%', placeholder: t('LOTS.ALLOTMENT'), disabled: true }"
                                    v-model="reserve.allotment_id"
                                    :options="allotments"/>
                        </div>
                    </div>
                    <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <div class="form-group">
                            <label
                                    for="reserveType" class="required">{{ t('NAV.RESERVE_TYPES') }}: </label>
                            <Select2
                                    :settings="{ width: '100%', disabled: true}"
                                    id="reserveType"
                                    name="reserveType"
                                    v-model="reserve.reserve_type_id"
                                    :options="reserveTypes"
                                    @select="selectGeneric(reserve.reserve_type_id, 'ReserveTypes'), validateReserveDuration(reserve.reserve_type_id, reserveTypes)"
                                    v-if="isRealtor"/>
                            <Select2
                                    :settings="{ width: '100%', placeholder: t('GENERAL.MSG.SELECT_ONE', {article: 'um', name: t('NAV.RESERVE_TYPES')}) }"
                                    id="reserveType" name="reserveType" v-model="reserve.reserve_type_id"
                                    :options="reserveTypes"
                                    @select="selectGeneric(reserve.reserve_type_id, 'ReserveTypes'), validateReserveDuration(reserve.reserve_type_id, reserveTypes)"
                                    v-if="!isRealtor"/>
                            <div
                                    class="validation"
                                    id="invalidReserveTypes"
                                    style="display: none">
                                {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                            </div>
                        </div>
                    </div>
                    <div
                            class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                        <div class="form-group">
                            <label
                                    for="block" class="required">{{ t('LOTS.BLOCK') }}: </label>
                            <Select2
                                    :settings="{ width: '100%', placeholder:  reserve.blocks, disabled: true}"
                                    v-if="reserve.isLotOrigin"/>
                            <Select2
                                    :settings="{ width: '100%', placeholder: '000', disabled: true}"
                                    :options="block"
                                    v-if="isLoadingBlocks && !reserve.isLotOrigin"/>
                            <Select2
                                    :settings="{ width: '100%', placeholder: '000' }"
                                    id="block"
                                    name="block"
                                    v-if="!isLoadingBlocks && !reserve.isLotOrigin"
                                    v-model="reserve.block"
                                    :options="blocks"
                                    @select="blockSelected($event, reserve.allotment_id)"/>
                            <div class="validation"
                                 id="invalidBlock" style="display: none">{{
                                    t('GENERAL.MSG.REQUIRED_FIELD')
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6">
                        <div class="form-group">
                            <label
                                    for="lot" class="required">{{ t('LOTS.LOT') }}: </label>
                            <Select2 :settings="{ width: '100%', disabled: true}"
                                     v-model="reserve.property_id"
                                     :options="reserve.lots" v-if="reserve.isLotOrigin"/>
                            <Select2
                                    :settings="{ width: '100%', placeholder: '0000', disabled: true}"
                                    :options="lots"
                                    v-if="isLoadingLots && !reserve.isLotOrigin"/>
                            <Select2
                                    :settings="{ width: '100%', placeholder: '0000'}"
                                    id="lot"
                                    name="lot"
                                    v-model="reserve.property_id"
                                    :options="lots"
                                    @select="selectGeneric(reserve.property_id, 'Lot')"
                                    v-if="!isLoadingLots && !reserve.isLotOrigin"/>
                            <div class="validation"
                                 id="invalidLot" style="display: none">{{
                                    t('GENERAL.MSG.REQUIRED_FIELD')
                                }}
                            </div>
                        </div>
                    </div>
                    <div
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div class="form-group"
                             v-if="hasDuration">
                            <label
                                    for="reserveDuration" class="required">{{ t('RESERVE.DURATION') }}: </label>
                            <input
                                    class="form-control text-center"
                                    type="text"
                                    id="reserveDuration"
                                    name="reserveDuration"
                                    v-model.lazy="reserve.reserve_duration"
                                    v-money3="configDuration"
                                    @keyup="inputReserve"
                                    :disabled="isLoadingBlocks || isRealtor">
                            <div class="validation"
                                 id="invalidReserve" style="display: none">{{ msgReserve }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                        class="form-group mt-2">
                    <div class="w-100 d-flex justify-content-between">
                        <label
                                :class="isRequiredDataClient && this.isAdmin !== 1 ? 'required' : ''"
                                for="clients"
                                class="align-self-end"
                        >{{ t('CLIENTS.INDEX_TITLE') }}:
                        </label>
                        <button
                                class="btn btn-primary mb-1"
                                @click.stop.prevent="setAddClient"><i
                                class="icon-circle-with-plus"></i></button>
                    </div>

                    <div
                            class="card-custom background-header"
                            id="clients">
                        <div
                                class="row gutters ml-1 pt-1 mb-0 pb-0"
                                style="min-height: 40px">
                            <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 d-flex">
                                <label class="align-self-center">{{ t('COMPANIES.TAXPAYER') }}: </label>
                            </div>
                            <div
                                    class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 d-flex">
                                <label class="align-self-center">{{ t('GENERAL.NAME') }}: </label>
                            </div>
                            <div
                                    class="col-xl-3 col-lg-3 col-md-10 col-sm-10 col-10 d-flex">
                                <label class="align-self-center">{{ t('GENERAL.PHONE') }}: </label>
                            </div>
                            <div
                                    class="col-xl-3 col-lg-3 col-md-10 col-sm-10 col-10 d-flex">
                                <div class="align-self-center">
                                    <label
                                            for="phone">{{ t('GENERAL.EMAIL') }}: </label>
                                </div>
                            </div>
                        </div>
                        <div
                                class="background-table padding border-top"
                                v-for="(client, key) in clients"
                                :key="key">
                            <div class="row gutters ml-1 alin-text">
                                <div class="'col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <p>{{ client.taxpayer }}</p>
                                </div>
                                <div class="'col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <p>{{ client.name }}</p>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-7 col-sm-7 col-7">
                                    <vue-tel-input
                                            v-model="client.phones[0].number"
                                            disabled
                                            mode="national"
                                            :autoFormat="true"
                                            :preferredCountries="['US', 'DE', 'FR', 'CN']"
                                            :defaultCountry="client?.phones[0]?.code_country ? parseInt(client.phones[0].code_country) : 55"
                                            :dropdownOptions="dropdownOptions"
                                            :inputOptions="inputOptions"
                                    ></vue-tel-input>
                                </div>
                                <div
                                        class="col-xl-3 col-lg-3 col-md-10 col-sm-10 col-10">
                                    <p>{{ client.email ? client.email : 'Não Informado' }}</p>
                                </div>
                                <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 text-right">
                                    <a
                                            class="btn btn-custom bg-btn mr-1"
                                            :title="t('ACTIONS.DELETE')"
                                            @click.stop.prevent="deleteClient(key)">
                                        <i
                                                class="icon-trash"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                                class="background-table padding-null border-top text-center"
                                v-if="!clients[0]">
                            <p>{{ t('GENERAL.MSG.NONE_COSTUMER_SELECTED') }}</p>
                        </div>
                    </div>
                </div>
                <div class="row gutters">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label
                                    for="realtor" class="required">{{ t('RESERVE.REALTOR') }}: </label>
                            <Select2
                                    :settings="{ width: '100%', placeholder: t('GENERAL.MSG.SELECT_ONE', {article: 'um', name: t('RESERVE.REALTOR')}), disabled: true}"
                                    :options="realtors"
                                    v-if="isLoadingBlocks"/>
                            <Select2
                                    :settings="{ width: '100%', disabled: realtors?.length===1}"
                                    id="realtor"
                                    name="realtor"
                                    v-model="reserve.realtor_id" :options="realtors"
                                    v-if="!isLoadingBlocks && isRealtor"
                                    @select="selectGeneric(reserve.realtor_id, 'Realtors')"/>
                            <Select2
                                    :settings="{ width: '100%', placeholder: t('GENERAL.MSG.SELECT_ONE', {article: 'um', name: t('RESERVE.REALTOR')}) }"
                                    id="realtor" name="realtor"
                                    v-model="reserve.realtor_id" :options="realtors"
                                    v-if="!isLoadingBlocks && !isRealtor"
                                    @select="selectGeneric(reserve.realtor_id, 'Realtors')"/>
                            <div class="validation"
                                 id="invalidRealtors" style="display: none">{{
                                    t('GENERAL.MSG.REQUIRED_FIELD')
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gutters">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="form-group">
                            <label
                                    for="comments">{{ t('GENERAL.COMMENTS') }}: </label>
                            <textarea
                                    class="form-control"
                                    id="comments"
                                    rows="3"
                                    v-model="reserve.observation"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div
                    class="modal-footer"
                    style="justify-content: space-between">
                <div>
                    <h5
                            class="ml-3"
                            style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
                </div>
                <div>
                    <button
                            data-cy="reserve_create_close"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                            type="button"
                            @click="closeModal">
                        {{ t('ACTIONS.CLOSE') }}
                    </button>
                    <button
                            data-cy="reserve_create_save"
                            class="btn btn-primary ml-2"
                            type="submit">{{
                            t('ACTIONS.SAVE')
                        }}
                    </button>
                </div>
            </div>
        </form>
        <transition
                name="fade">
            <div
                    class="modal-mask"
                    v-if="addClient">
                <div
                        class="modal-wrapper"
                        data-backdrop="static"
                        data-keyboard="false">
                    <div
                            class="modal-dialog lg"
                            role="document"
                            style="margin-top: 0rem;">
                        <div class="modal-content">
                            <div class="modal-header modal-header-color">
                                <h4 class="modal-title">


                                    Adicionar Cliente


                                </h4>
                                <button type="button"
                                        class="closeX"
                                        id="closeX"
                                        @click.stop.prevent="closeModalClient(false)">
                                    <span
                                            aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div>
                                <div
                                        class="modal-body  mt-2"
                                        style="max-height: calc(100vh - 200px)">
                                    <div class="row gutters">
                                        <div
                                                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                            <div class="form-group">
                                                <label
                                                        for="taxpayer">{{ t('COMPANIES.TAXPAYER') }}: </label>
                                                <input type="text"
                                                       id="taxpayer"
                                                       name="taxpayer"
                                                       v-model="client.taxpayer"
                                                       class="form-control"
                                                       v-mask="['###.###.###-##', '##.###.###/####-##']"
                                                       @keyup="inputTaxpayer(client.taxpayer)"
                                                       @blur="searchClient(client)"
                                                       placeholder="000.000.000-00">
                                                <div
                                                        v-if="isTaxpayerInvalid" class="validation">{{ t(msgTaxpayer) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                            <div class="form-group">
                                                <label
                                                        for="brock">{{ t('GENERAL.NAME') }}: </label>
                                                <input type="text"
                                                       id="name"
                                                       name="name"
                                                       v-model="client.name" class="form-control"
                                                       maxlength="60"
                                                       @keyup="inputName(client.name)"
                                                       placeholder="Nome Completo">
                                                <div
                                                        v-if="isNameInvalid" class="validation">{{ t(msgError) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                            <div class="form-group">
                                                <label for="phone">{{ t('GENERAL.PHONE') }}: </label>
                                                <vue-tel-input
                                                        v-model="client.phones[0].number"
                                                        :autoFormat="true"
                                                        :preferredCountries="['US', 'DE', 'FR', 'CN']"
                                                        mode="national"
                                                        :defaultCountry="client?.phones[0]?.code_country ? client.phones[0].code_country : 55"
                                                        @country-changed="changeCountry"
                                                        :dropdownOptions="dropdownOptions"
                                                        :inputOptions="inputOptions"
                                                ></vue-tel-input>
                                                <div class="validation"
                                                     id="invalidphone" style="display: none">{{
                                                        msgPhone ? t(msgPhone) : ''
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                            <div class="form-group">
                                                <label for="phone">{{ t('GENERAL.EMAIL') }}: </label>
                                                <input type="text"
                                                       id="clientEmail"
                                                       name="clientEmail" class="form-control"
                                                       placeholder="E-mail"
                                                       v-model="client.email">
                                                <div class="validation"
                                                     id="invalidEmail"
                                                     v-if="msgEmail">{{
                                                        t(msgEmail)
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                    class="modal-footer justify-content-end">
                                <button
                                        class="btn btn-secondary mr-4" type="button"
                                        @click.stop.prevent="closeModalClient(false)">{{
                                        t("ACTIONS.CLOSE")
                                    }}
                                </button>
                                <button
                                        data-cy="user_index_add_phone_save"
                                        class="btn btn-primary" type="button"
                                        @click.stop.prevent="selectClient(client)">{{ t("ACTIONS.SAVE") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import Lots from '@/services/Lots';
import User from '@/services/user';
import Clients from '@/services/Clients';
import {Money3Directive} from 'v-money3';
import Reserve from '@/services/Reserves';
import {useToast} from "vue-toastification";
import ReserveTypes from "@/services/ReserveTypes";
import Select2 from 'vue3-select2-component';
import Allotments from '@/services/Allotments';
import Validate from '@/components/validate.vue';
import jwt_decode from "jwt-decode";
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
    name: 'createReserve',
    props: ['reserveIndex', 'allotmentsIndex', 'blocksIndex', 'reserveTypesIndex', 'isRealtor', 'idModal', 'isDataRequired', 'isEmailRequired'],
    mixins: [Validate],
    directives: {money3: Money3Directive},
    components: {
        Select2,
        VueTelInput,
    },

    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },

    data() {
        return {
            dropdownOptions: {
                showDialCodeInList: true,
                showDialCodeInSelection: true,
                showFlags: true,
                showSearchBox: true,
            },
            inputOptions: {
                autocomplete: 'on',
                autofocus: true,
                'aria-describedby': 'des',
                showDialCode: false,
                placeholder: '',
                tabindex: 1050,
            },
            addClient: false,
            form: null,
            lots: null,
            dataRequired: false,
            block: null,
            reserve: {},
            msgEmail: null,
            invalid: [],
            clients: [],
            isEmailNull: false,
            realtors: [],
            isAdmin: null,
            blocks: null,
            isRequiredDataClient: null,
            msgReserve: '',
            clientsIds: [],
            typeOnlyCall: 1,
            allotments: null,
            isRequiredEmail: false,
            reserveTypes: null,
            hasDuration: false,
            isLoadingLots: true,
            isLoadingBlocks: true,
            reserveDurationMax: null,
            configEditor: {
                height: '200'
            },
            client: {
                phones: [
                    {
                        type: 1,
                        number: '',
                        description: null,
                        code_country: 55,
                        country_acronym: 'BR',
                    }
                ]
            },
            configDuration: {
                min: 0,
                max: 999,
                masked: true,
                decimal: ':',
                precision: 2,
                disabled: false,
                allowBlank: false,
                disableNegative: true,
                minimumNumberOfCharacters: 0
            },
        }
    },

    mounted() {
        this.blocks = this.blocksIndex;
        this.reserve = this.reserveIndex;
        this.allotments = this.allotmentsIndex;
        this.isRequiredDataClient = this.isDataRequired;
        this.checkIfisDataClientRequired();
        this.allotmentSelected(this.reserve.allotment_id, true);
    },
    watch: {
        isDataRequired: function () {
            document.getElementById('clients').style.backgroundColor = '';
            this.isRequiredDataClient = this.isDataRequired;
        },
        isEmailRequired: function () {
            this.isRequiredEmail = this.isEmailRequired;
        },
        reserveIndex: function () {
            this.clients = [];
            this.resetClient();
            this.clientsIds = [];
            this.hasDuration = false;
            this.isNameInvalid = false;
            this.isLoadingBlocks = true;
            this.blocks = this.blocksIndex;
            this.isTaxpayerInvalid = false;
            this.reserve = this.reserveIndex;
            this.allotments = this.allotmentsIndex;
            this.allotmentSelected(this.reserve.allotment_id, true);
            document.getElementById('invalidphone').style.display = 'none';
            if (document.getElementById('reserveDuration')) {
                document.getElementById('reserveDuration').classList.remove('is-valid');
                document.getElementById('reserveDuration').classList.remove('is-invalid');
            }

        }
    },
    methods: {
        changeCountry(val) {
            this.client.phones[0].code_country = val.dialCode;
            this.client.phones[0].country_acronym = val.iso2;
        },
        closeModalClient(isValidate = false) {
            if (isValidate && this.isTaxpayerInvalid) {
                return;
            }
            this.msgEmail = false;
            this.isNameInvalid = false;
            this.isTaxpayerInvalid = false;
            this.client.taxpayer = null;
            this.client.name = null;
            this.client.email = null;
            this.addClient = false;
            this.client = {
                phones: [
                    {
                        type: 1,
                        number: '',
                        description: null,
                        code_country: 55,
                        country_acronym: 'BR',
                    }
                ]
            }
        },
        setAddClient() {
            this.addClient = true;
        },
        closeModal() {
            document.getElementById('closeX').click();
            this.$emit('close');
        },
        async indexReserveTypes(allotment_id) {
            if (allotment_id) {
                await ReserveTypes.list(allotment_id).then(resp => {
                    this.reserveTypes = resp.data;
                }).catch(error => {
                    this.errorMsg(error);
                });
            }

            this.reserve.reserveTypes = [];

            if (this.isRealtor) {
                this.reserve.reserve_type_id = this.reserveTypes[0].id;
                this.validateReserveDuration(this.reserveTypes[0].id, this.reserveTypes);
            }
        },

        checkIfisDataClientRequired() {
            let companies = jwt_decode(localStorage.getItem('access_token'))
                    .roles['companies'];
            companies.forEach(company => {
                company.companies.forEach(id => {
                    if (localStorage.getItem('companyId') === id) {
                        this.isAdmin = company.group_id;
                    }
                })
            })
        },

        indexBlocks(allotment_id) {
            Lots.blocksListAvailable(allotment_id).then(async resp => {
                if (!resp.data.validations) {
                    await (this.blocks = resp.data);
                }
            }).catch(error => {
                this.errorMsg(error);
            });
        },

        indexRealtor(id) {
            User.listRealtor(id).then(resp => {
                this.realtors = resp.data;

                if (this.isRealtor && this.realtors?.length == 1) {
                    this.reserve.realtor_id = resp.data[0].id;
                }

            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error)
            });
        },

        async indexLots(allotment_id, block) {
            this.isLoadingLots = true;

            await Lots.showLotProperties(allotment_id, block).then(resp => {
                this.lots = resp.data

                this.isLoadingLots = false;
            });
        },

        selectClient(client) {
            if (this.checkClient(client)) {
                if (this.clients.length >= 1) {
                    if (!this.clients.find(c => c.taxpayer === client.taxpayer)) {
                        if (client.id) {
                            if (this.isRequiredEmail && this.isEmailNull) {
                                client.simpleInsert = true;
                                Clients.update(client).then(() => {
                                    this.clients.push(client);
                                    this.clientsIds.push({id: client.id});
                                    this.resetClient();
                                    this.closeModalClient(true);
                                }).catch((err) => {
                                    this.errorMsg(err)
                                })
                            } else {
                                this.clients.push(client);
                                this.clientsIds.push({id: client.id});
                                this.resetClient();
                                this.closeModalClient(true);
                            }
                        } else {
                            client.simpleInsert = true;
                            Clients.create(client).then(resp => {
                                this.clients.push(resp.data);
                                this.clientsIds.push({id: resp.data.id});
                                this.resetClient();
                                this.closeModalClient(true);
                            }).catch((err) => {
                                this.errorMsg(err)
                            });
                        }
                    } else {
                        this.toast.error(this.t('GENERAL.MSG.PREVIOUSLY_REGISTERED_CUSTOMER_INFORMATION'), {timeout: false});
                    }
                } else {
                    if (client.id) {
                        if (this.isRequiredEmail && this.isEmailNull) {
                            client.simpleInsert = true;
                            Clients.update(client).then(() => {
                                this.clients.push(client);
                                this.clientsIds.push({id: client.id});
                                this.resetClient();
                                this.closeModalClient(true);
                            }).catch((err) => {
                                this.errorMsg(err)
                            })
                        } else {
                            this.clients.push(client);
                            this.clientsIds.push({id: client.id});
                            this.resetClient();
                            this.closeModalClient(true);
                        }
                    } else {
                        client.simpleInsert = true;
                        Clients.create(client).then(resp => {
                            this.clients.push(resp.data);
                            this.clientsIds.push({id: resp.data.id});
                            this.resetClient();
                            this.closeModalClient(true);
                        }).catch(err => {
                            this.errorMsg(err)
                        });
                    }
                }
            }
        },

        checkClient(client) {
            this.invalid = [];
            this.nameValidate(client.name);
            if (!client.phones[0]?.number || !client.phones[0]?.number?.length > 3) {
                this.invalid.push(true);
                document.getElementById("invalid" + 'phone').style.display = "block";
                this.msgPhone = "GENERAL.MSG.REQUIRED_FIELD";
            } else {
                document.getElementById("invalid" + 'phone').style.display = "none";
                this.msgPhone = null;
            }

            if (this.isDataRequired && this.isRequiredEmail && !client?.email?.length) {
                document.getElementById('clientEmail')?.classList?.add("is-invalid");
                this.msgEmail = "GENERAL.MSG.REQUIRED_FIELD";
                return false;
            } else {
                document.getElementById('clientEmail')?.classList?.remove("is-invalid");
                this.msgEmail = null;
            }

            if (this.invalid.length && this.invalid.length > 0) {
                return false;
            }
            if (this.isTaxpayerInvalid) {
                return false;
            }
            return true;
        },

        checkForm() {
            this.invalid = [];

            this.reserveDurationValidate();
            this.validateGeneric(this.reserve.block, 'Block', false);
            this.validateGeneric(this.reserve.property_id, 'Lot', false);
            this.validateGeneric(this.reserve.realtor_id, 'Realtors', false);
            this.validateGeneric(this.reserve.reserve_type_id, 'ReserveTypes', false);


            if (this.invalid.length && this.invalid.length > 0) {
                return false;
            }
            return true;
        },

        resetClient() {
            this.client = {
                phones: [{
                    type: 1,
                    number: '',
                    description: null,
                    code_country: 55,
                    country_acronym: 'BR',
                }]
            }
            document.getElementById('name')?.classList?.remove('is-valid');
            document.getElementById('phone')?.classList?.remove('is-valid');
            document.getElementById('name')?.classList?.remove('is-invalid');
            document.getElementById('phone')?.classList?.remove('is-invalid');
            document.getElementById('taxpayer')?.classList?.remove('is-valid');
            document.getElementById('taxpayer')?.classList?.remove('is-invalid');
        },

        deleteClient(position) {
            this.clients.splice(position, 1);
            this.clientsIds.splice(position, 1);
        },

        reserveDurationValidate() {
            if (this.reserve.reserve_duration) {
                var timeReserve = this.reserve.reserve_duration.split(":");

                if (timeReserve[1] > 59) {
                    this.reserveInvalid(this.t('GENERAL.MSG.INCORRECT_MINUTES'));
                }
            } else if (this.hasDuration) {
                this.reserveInvalid(this.t('GENERAL.MSG.REQUIRED_FIELD'));
            }
        },

        reserveInvalid(msg) {
            this.msgReserve = msg;
            this.invalid.push(true);
            document.getElementById('invalidReserve').style.display = 'block';
            document.getElementById('reserveDuration').classList.add('is-invalid');
        },

        inputReserve() {
            if (this.hasDuration) {
                document.getElementById('invalidReserve').style.display = 'none';
                document.getElementById('reserveDuration').classList.remove('is-valid');
                document.getElementById('reserveDuration').classList.remove('is-invalid');
                this.reserveDurationValidate();
            }
        },

        searchClient(client) {
            this.invalid = [];
            this.validateTaxpayer(client.taxpayer);
            if (this.invalid.length == 0) {
                this.$store.commit('changeLoading', true);
                const tax = Buffer.from(client.taxpayer).toString('base64');
                Clients.showByTaxpayer(tax).then(resp => {
                    if (resp.data.id) {
                        if (!resp.data?.phones?.length) {
                            resp.data.phones = [{
                                type: 1,
                                number: '',
                                description: null,
                                code_country: this.client?.phones[0]?.code_country ? parseInt(this.client.phones[0].code_country) : 55,
                                country_acronym: this.client?.phones[0]?.country_acronym ? this.client.phones[0].country_acronym : 'BR',
                            }];
                        }
                        if (resp.data?.created_by && !this.reserve?.realtor_id) {
                            this.realtors.forEach(i => {
                                if (i.id === resp.data?.created_by) {
                                    this.reserve.realtor_id = i.id;
                                }
                            });
                        }
                        this.isEmailNull = !resp.data.email;
                        if (resp.data.company_id !== localStorage.getItem('companyId')) {
                            resp.data.id = null;
                            this.client = resp.data;
                            this.client.id = null;
                        } else {
                            this.client = resp.data;
                        }
                        this.inputName(this.client.name);
                    } else {
                        this.client = {
                            taxpayer: client.taxpayer,
                            name: null,
                            email: null,
                            phones: [{
                                type: 1,
                                number: '',
                                description: null,
                                code_country: this.client?.phones[0]?.code_country ? parseInt(this.client.phones[0].code_country) : 55,
                                country_acronym: this.client?.phones[0]?.country_acronym ? this.client.phones[0].country_acronym : 'BR',
                            }]
                        };
                    }
                    this.$store.commit('changeLoading', false);
                })
            }
        },

        checkSingularClient() {
            let hasClient = false;
            if (this.client.taxpayer) hasClient = true;
            if (this.client.name) hasClient = true;
            if (this.client?.phones[0] && this.client.phones[0]?.number) hasClient = true;

            if (hasClient) {
                document.getElementById('clients').style.backgroundColor = 'rgba(233,142,142,0.3)';
                this.toast.error(this.t('RESERVE.ADD_CLIENT_OR_CLEAR'));
            } else {
                document.getElementById('clients').style.backgroundColor = '';
            }
            return hasClient;
        },

        create() {
            if (this.isRequiredDataClient && this.isAdmin !== 1) {
                if (this.clientsIds.length < 1) {
                    this.toast.error(this.t('GENERAL.MSG.PLEASE_ADD_A_CLIENT_TO_GO_AFTER'), {timeout: false});
                    return;
                }
            }

            if (this.checkForm()) {
                if (this.checkSingularClient()) return;
                this.$store.commit('changeLoading', true);
                this.updateForm();

                Reserve.create(this.form, this.reserve.allotment_id).then(() => {
                    document.getElementById('closeX').click();
                    this.toast.success(this.t('GENERAL.MSG.REGISTER_SUCCESS'));
                    this.$store.commit('changeLoading', false);

                    if (this.reserve.isLotOrigin) {
                        let resp = {
                            allotment_id: this.reserve.allotment_id,
                            block_id: this.reserve.blocks
                        }
                        this.$emit('created', resp);
                    } else {
                        this.$emit('created');
                    }

                }).catch(error => {
                    this.errorMsg(error)
                    this.$store.commit('changeLoading', false);
                });
            }
        },


        updateForm() {
            this.reserve.clients = this.clientsIds;
            this.reserve.realtor = this.reserve.realtor == 0 ? null : this.reserve.realtor;
            this.reserve.reserve_type_id = this.reserve.reserve_type_id == 0 ? null : this.reserve.reserve_type_id;

            this.form = {
                observation: this.reserve.observation,
                property_id: this.reserve.property_id,
                reserve_type_id: this.reserve.reserve_type_id,
                realtor_id: this.reserve.realtor_id,
                reserve_duration: this.reserve.reserve_duration,
                clients: this.reserve.clients,
            }
        },

        async allotmentSelected(allotment_id, isMounted = false) {
            this.$store.commit('changeLoading', true);
            this.indexRealtor(allotment_id);
            this.indexBlocks(allotment_id);

            if (!isMounted) {
                this.selectGeneric(allotment_id, 'Allotment');
            }

            await Allotments.show(allotment_id).then(resp => {
                if (resp.data.reserve_duration_max) {
                    this.reserveDurationMax = resp.data.reserve_duration_max;

                    this.inputReserve();
                    this.indexReserveTypes(allotment_id);
                }
                this.isLoadingBlocks = false;
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.errorMsg(error)
                this.$store.commit('changeLoading', false);
            });
        },

        blockSelected(block, allotment_id) {
            this.indexLots(allotment_id, block.text);
            this.selectGeneric(allotment_id, 'Block');
        },

        validateReserveDuration(typeReserveId, typeReserves) {
            this.hasDuration = typeReserves.find(t => t.id == typeReserveId).has_limit_time;

            if (!this.hasDuration) {
                this.reserve.reserve_duration = null;
            } else {
                this.reserve.reserve_duration = this.reserveDurationMax;
            }
        },

        observationChange(observation) {
            this.reserve.observation = observation;
        },
    }
}
</script>
<style scoped>
.right-content {
    justify-content: flex-end;
}

.padding {
    padding: 0.4rem;
}

.background-header {
    background-color: #eeeeee;
}

.background-table {
    background-color: #fff;
}

.card-custom {
    border-radius: 3px;
    margin-bottom: 1rem;
    border: 1px solid #d7dbe2;
    border-bottom: 1px solid #d7dbe2;
}

.border-top {
    border-top: 1px solid #dee2e6;
}

.padding-null {
    padding: 0.75rem;
}

.alin-text {
    align-items: center;
}

.btn-custom {
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
}

.bg-btn {
    background-color: white !important;
    color: black !important;
}

.bg-btn:hover {
    background-color: #0e5e4e !important;
    color: white !important;
}

.modal {
    position: absolute;
}
@media (max-width: 810px) {
    ::v-deep .vti__dropdown-list{
        width: 47vw;
        max-width: 350px;
    }
}
</style>
