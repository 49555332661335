<template>
    <div></div>
</template>

<script>

import SimulatorConfig from '../services/SimulatorConfig';

export default {
    name: 'BuildSimulatorUrl',

    methods: {
        async buildSimulatorUrl(propertyId) {
            this.$store.commit('changeLoading', true);

            return await SimulatorConfig.getDataToBuildUrl(propertyId).then(async ({data}) => {
                const {reserveId, companyId} = data;

                const accessToken = reserveId ? `?accessToken=${localStorage.getItem('access_token')}` : '';

                return `${process.env.VUE_APP_SIMULATOR_HOST}/${companyId}/${propertyId}${accessToken}`;
            }).catch(err => {
                throw err;
            }).finally(() => this.$store.commit('changeLoading', false));
        },
    },
};

</script>
